




import Vue from 'vue';

export default class AddNewInvestorBoilerplate extends Vue {

  get leadFormItems() {
    return this.$store.getters['campaigns/leadFormData']?.parameters?.items;
  }

  get skip2ndStep() {
    const stepFields = ['title', 'middle_name', 'birth_date', 'birth_place', 'fathers_name', 'mothers_name', 'phone_number', 'identification_number', 'document_type', 'document_number', 'document_expiry_date', 'country', 'nationality'];

    return this.leadFormItems.filter((el: any) => stepFields.includes(el.name))?.length ? false : true;
  }

  get skip3rdStep() {
    const stepFields = ['residential_address_street', 'residential_address_house_number', 'residential_address_flat_number', 'residential_address_country', 'residential_address_city', 'residential_address_zip_code', 'mailing_address_street', 'mailing_address_house_number', 'mailing_address_flat_number', 'mailing_address_country', 'mailing_address_city', 'mailing_address_zip_code'];

    return this.leadFormItems.filter((el: any) => stepFields.includes(el.name))?.length ? false : true;
  }

  get capitalRiseLink() {
    return (this.$route.path.includes('company')) ? '/company/capital-rise' : '/fund/capital-rise';
  }

  displayField(name: string) {
    const field = this.leadFormItems?.find((el: any) => el.name === name);

    return (field) ? true : false;
  }

  isLeadFormFieldRequired(name: string) {
    const field = this.leadFormItems?.find((el: any) => el.name === name);

    return (field && field.required) ? true : false;
  }

  scrollToError(): void {
    const error: HTMLDivElement = this.$root.$el.querySelector('.app-content .error') as HTMLDivElement | undefined;
    error?.scrollIntoView({ behavior: 'smooth' });
  }
}

